import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';
import { onImageupload } from '@/plugins/utils';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem('user')),
    token: localStorage.getItem('token'),
    loggedIn: localStorage.getItem('token') ? true : false,
    loading: false,
    successTitle: 'Buen trabajo',
    warningTitle: 'Cuidado',
    errorTitle: 'Opsss',
    tinyMceConfig: {
      height: 500,
      inline: false,
      menubar: false,
      fontsize_formats:
        '8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 34px 38px 42px 48px 54px 60px',
      plugins:
        'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help code',
      toolbar1:
        'formatselect fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | hr | removeformat | image | table tabledelete | tableprops tablerowprops tablecellprops | code',
      image_advtab: true,
      file_picker_types: 'file image media',
      image_title: true,
      automatic_uploads: true,
      images_upload_handler: onImageupload,
      images_upload_credentials: true,
      powerpaste_word_import: 'clean',
      powerpaste_html_import: 'clean',
      language_url: '/js/tiny-mce-es.js',
      language: 'es',
      images_file_types: 'jpg,svg,webp,png,gif,jpeg',
      relative_urls: false,
    },
  },
  getters: {
    user: (state) => state.user,
    token: (state) => state.token,
    loggedIn: (state) => state.loggedIn,
    loading: (state) => state.loading,
    successTitle: (state) => state.successTitle,
    warningTitle: (state) => state.warningTitle,
    errorTitle: (state) => state.errorTitle,
    tinyMceConfig: (state) => state.tinyMceConfig,
  },
  mutations: {
    ON_MUT_LOGIN(state, payload) {
      const user = payload.user;
      const token = `${payload.token_type} ${payload.token}`;
      state.user = user;
      state.token = token;
      state.loggedIn = true;
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
      axios.defaults.headers.common.Authorization = token;
    },
    ON_MUT_LOGOUT(state) {
      state.user = null;
      state.token = null;
      state.loggedIn = false;
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      axios.defaults.headers.common.Authorization = '';
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    ON_SET_USER(state, payload) {
      state.user = payload;
      localStorage.setItem('user', JSON.stringify(payload));
    },
  },
  actions: {
    ON_SET_USER(context, payload) {
      context.commit('ON_SET_USER', payload);
    },
    onLogin(context, payload) {
      context.commit('ON_MUT_LOGIN', payload);
    },
    onLogout(context) {
      context.commit('ON_MUT_LOGOUT');
    },
    SET_LOADING(context, payload) {
      context.commit('SET_LOADING', payload);
    },
  },
  modules: {},
});
