import Vue from 'vue';
import axios from 'axios';
import { Notification } from 'element-ui';
import store from '../store';

const token = store.getters.token;
const commons = {
  'X-Requested-With': 'XMLHttpRequest',
//   'Content-Type': 'application/json',
  Accept: 'application/json',
};

if (token) {
  commons.Authorization = token;
}

axios.defaults.headers.common = commons;

axios.defaults.withCredentials = true;
const baseUrl = process.env.VUE_APP_BASE_URL;
axios.defaults.baseURL = baseUrl + 'api/';

axios.get('/sanctum/csrf-cookie', {
  baseURL: baseUrl,
});

axios.interceptors.request.use(
  (config) => {
    store.dispatch('SET_LOADING', true);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    store.dispatch('SET_LOADING', false);

    return response;
  },
  function (error) {
    const status = error.response.status;
    const data = error.response.data;
    if (status === 403) {
      Notification({
        title: store.getters.warningTitle,
        message: 'No estas autorizado para entrar a esta sección.',
        type: 'warning',
      });
      window.location = '/';
    } else if (status === 404) {
      Notification({
        title: store.getters.errorTitle,
        message: data.message || 'Lo que buscas no existe.',
        type: 'warning',
      });
    } else if (status === 500) {
      if (data.message) {
        Notification({
          title: store.getters.errorTitle,
          message:
            data.message ||
            'Ocurrió un error en nuestros servidores, recargue la pagína e intente de nuevo.',
          type: 'warning',
        });
      }
    }
    store.dispatch('SET_LOADING', false);
	
    return Promise.reject(error);
  }
);

Vue.prototype.$axios = axios;
