<template>
  <ValidationObserver
    v-slot="{ invalid, handleSubmit }"
    tag="div"
    class="form"
    ref="changeMyPasswordForm"
  >
    <form @submit.prevent="handleSubmit(onSubmit)">
      <el-dialog
        title="Cambiar mi contraseña"
        :visible="show"
        width="500px"
        @opened="onOpen"
        :before-close="onClose"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <ValidationProvider
          name="contraseña actual"
          rules="required|min:6"
          v-slot="{ errors }"
          class="form-group"
          vid="current_password"
          tag="div"
        >
          <label for="current_password">Contraseña actual</label>
          <el-input
            type="password"
            v-model="form.current_password"
            id="current_password"
          ></el-input>
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider
          name="contraseña nueva"
          rules="required|min:6|confirmed:password_confirmation"
          v-slot="{ errors }"
          class="form-group"
          vid="password"
          tag="div"
        >
          <label for="password">Contraseña nueva</label>
          <el-input
            type="password"
            v-model="form.password"
            id="password"
          ></el-input>
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider
          name="confirmar contraseña nueva"
          rules="required"
          v-slot="{ errors }"
          class="form-group"
          vid="password_confirmation"
          tag="div"
        >
          <label for="password_confirmation">Confirmar contraseña nueva</label>
          <el-input
            type="password"
            v-model="form.password_confirmation"
            id="password_confirmation"
          ></el-input>
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        <span slot="footer" class="dialog-footer">
          <el-button @click="onClose">Cancelar</el-button>
          <el-button
            type="primary"
            native-type="submit"
            :disabled="invalid || loading"
            >Guardar</el-button
          >
        </span>
      </el-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    form: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
  }),
  computed: {
    ...mapGetters(['loading', 'successTitle']),
  },
  methods: {
    onSubmit() {
      this.$axios
        .put(`change-password`, this.form)
        .then((response) => {
          this.$notify({
            title: this.successTitle,
            message: response.data.message,
            type: 'success',
          });
          this.onClose();
        })
        .catch((error) => this.onAxiosErrors422(error, 'changeMyPasswordForm'));
    },
    onOpen() {
      this.form.current_password = '';
      this.form.password = '';
      this.form.password_confirmation = '';
    },
    onClose() {
      this.$emit('update:show', false);
    },
  },
};
</script>
