import Vue from 'vue';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
  localize,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import es from 'vee-validate/dist/locale/es.json';

configure({
  useConstraintAttrs: false,
});

localize('es', es);

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

// extend("decimal", {
//   validate: (value, { decimals = "*", separator = "." } = {}) => {
//     if (value === null || value === undefined || value === "") {
//       return {
//         valid: false
//       };
//     }
//     if (Number(decimals) === 0) {
//       return {
//         valid: /^-?\d*$/.test(value)
//       };
//     }
//     const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
//     const regex = new RegExp(
//       `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
//     );

//     return {
//       valid: regex.test(value)
//     };
//   },
//   message: "El campo {_field_} debe contener solo valores decimales"
// });

// extend("url", {
//   validate: value => {
//     if (value) {
//       return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
//         value
//       );
//     }

//     return false;
//   },
//   message: "El campo {_field_} no es una url válida"
// });

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
