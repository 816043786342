import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import AuthLayout from '@/layouts/Auth.vue';
import DashboardLayout from '@/layouts/Dashboard.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: 'auth/login',
  },
  {
    path: '/auth',
    component: AuthLayout,
    meta: {
      requiredAuth: false,
    },
    children: [
      {
        path: 'login',
        name: 'login',
        component: () =>
          import(/* webpackChunkName: "auth" */ '@/views/auth/Login.vue'),
      },
    ],
  },
  {
    path: '/dashboard',
    component: DashboardLayout,
    meta: {
      requiredAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/HomeView.vue'),
      },
      {
        path: 'settings',
        name: 'settings',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/SettingsView.vue'
          ),
      },
      {
        path: 'users',
        name: 'users',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/users/ListView.vue'
          ),
      },
      {
        path: 'seo',
        name: 'seo',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/seo/ListView.vue'
          ),
      },
      {
        path: 'blog/categories',
        name: 'blogCategories',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/blog/categories/ListView.vue'
          ),
      },
      {
        path: 'blog/posts',
        name: 'blogPosts',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/blog/posts/ListView.vue'
          ),
      },
      {
        path: 'blog/posts/create',
        name: 'blogPostsCreate',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/blog/posts/CreateEditView.vue'
          ),
      },
      {
        path: 'blog/posts/:id/edit',
        name: 'blogPostsEdit',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/blog/posts/CreateEditView.vue'
          ),
      },
      {
        path: 'services/sales-consultants',
        name: 'servicesSalesConsultantsPage',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/services/sales-consultants/ListView.vue'
          ),
      },
      {
        path: 'services/payment-methods',
        name: 'servicesPaymentMethodsPage',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/services/payment-methods/ListView.vue'
          ),
      },
      {
        path: 'services/types',
        name: 'servicesTypesPage',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/services/types/ListView.vue'
          ),
      },
      {
        path: 'services',
        name: 'servicesPage',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/services/services/ListView.vue'
          ),
      },
      {
        path: 'services/create',
        name: 'servicesCreatePage',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/services/services/AddEditView.vue'
          ),
      },
      {
        path: 'services/:id/edit',
        name: 'servicesEditPage',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/services/services/AddEditView.vue'
          ),
      },
      {
        path: 'services/:id/syllabus',
        name: 'servicesSyllabusPage',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/services/syllabus/ListView.vue'
          ),
      },
      {
        path: 'services/:id/virtual-mode',
        name: 'servicesVirtualMode',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/services/services/VirtualMode.vue'
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  const requiredAuth = to.matched.some((route) => route.meta.requiredAuth);
  const isAuthenticated = store.getters.loggedIn;
  if (to.name === 'verify_email') {
    next();
  } else if (requiredAuth && !isAuthenticated) {
    next({ name: 'login' });
  } else if (!requiredAuth && isAuthenticated) {
    next({ name: 'dashboard' });
  } else {
    next();
  }
});

export default router;
