<template>
  <ValidationObserver
    v-slot="{ invalid, handleSubmit }"
    tag="div"
    class="form"
    ref="changeMyProfileForm"
  >
    <form @submit.prevent="handleSubmit(onSubmit)">
      <el-dialog
        title="Mi información"
        :visible="show"
        width="500px"
        @opened="onOpen"
        :before-close="onClose"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <ValidationProvider
          name="nombres"
          rules="required|max:100"
          v-slot="{ errors }"
          class="form-group"
          vid="name"
          tag="div"
        >
          <label for="name">Nombres</label>
          <el-input
            type="text"
            v-model="form.name"
            id="name"
            maxlength="100"
            show-word-limit
          ></el-input>
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        <div class="form-group">
          <label for="email">Correo electrónico</label>
          <el-input
            type="email"
            readonly
            v-model="form.email"
            id="email"
          ></el-input>
        </div>
        <div class="form-group">
          <label for="type">Tipo de usuario</label>
          <el-input
            type="text"
            :value="`${user.type === 'ADMIN' ? 'Administrador' : 'Editor'}`"
            id="type"
            readonly
          ></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="onClose">Cancelar</el-button>
          <el-button
            type="primary"
            native-type="submit"
            :disabled="invalid || loading"
            >Guardar</el-button
          >
        </span>
      </el-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    form: {
      name: '',
      type: '',
      email: '',
      id: '',
    },
  }),
  computed: {
    ...mapGetters(['loading', 'successTitle', 'user']),
  },
  methods: {
    onSubmit() {
      this.$axios
        .put(`profile`, this.form)
        .then((response) => {
          this.$store.dispatch('ON_SET_USER', this.form);
          this.$notify({
            title: this.successTitle,
            message: response.data.message,
            type: 'success',
          });
          this.onClose();
        })
        .catch((error) => this.onAxiosErrors422(error, 'changeMyProfileForm'));
    },
    onOpen() {
      this.form.type = this.user.type;
      this.form.email = this.user.email;
      this.form.name = this.user.name;
      this.form.id = this.user.id;
    },
    onClose() {
      this.$emit('update:show', false);
    },
  },
};
</script>
