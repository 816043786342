<template>
  <el-container class="layout">
    <el-aside width="200px">
      <div class="text-center p-2">
        <img alt="Vue logo" src="@/assets/logo-gruporya.png" width="140" />
      </div>
      <el-menu v-if="user" router>
        <el-menu-item
          v-if="user.type === 'ADMIN'"
          index="settings"
          :route="{ name: 'settings' }"
        >
          <i class="fa-solid fa-gears menu__icon"></i>
          <span>Configuración</span>
        </el-menu-item>
        <el-menu-item
          v-if="user.type === 'ADMIN'"
          index="users"
          :route="{ name: 'users' }"
        >
          <i class="fa-solid fa-users menu__icon"></i>
          <span>Usuarios</span>
        </el-menu-item>
        <el-menu-item
          v-if="user.type === 'ADMIN'"
          index="seo"
          :route="{ name: 'seo' }"
        >
          <i class="fa-solid fa-chart-pie menu__icon"></i>
          <span>SEO</span>
        </el-menu-item>
        <el-submenu index="blog">
          <template slot="title">
            <i class="fa-regular fa-newspaper menu__icon"></i>
            <span>Blog</span></template
          >
          <el-menu-item-group>
            <el-menu-item
              index="blog-categories"
              :route="{ name: 'blogCategories' }"
              >Categorías</el-menu-item
            >
            <el-menu-item index="blog-posts" :route="{ name: 'blogPosts' }"
              >Artículos</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="services">
          <template slot="title">
            <i class="fas fa-headset menu__icon"></i>
            <span>Servicios</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              index="services-sales-consultants"
              :route="{ name: 'servicesSalesConsultantsPage' }"
              >Asesores de venta</el-menu-item
            >
            <el-menu-item
              index="services-payment-methods"
              :route="{ name: 'servicesPaymentMethodsPage' }"
              >Métodos de pago</el-menu-item
            >
            <el-menu-item
              index="services-types"
              :route="{ name: 'servicesTypesPage' }"
              >Tipos</el-menu-item
            >
            <el-menu-item
              index="services-services"
              :route="{ name: 'servicesPage' }"
              >Servicios</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-aside>

    <el-container>
      <el-header class="layout__header d-flex align-center">
        <el-dropdown v-if="user" class="ms-auto" trigger="click">
          <el-button type="text">
            <div class="d-flex align-center">
              <img
                src="@/assets/user.png"
                alt="Avatar"
                width="30"
                class="me-1"
              />
              <span>{{ user.name }}</span>
            </div>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="onUpdateProfile"
              >Mi perfil</el-dropdown-item
            >
            <el-dropdown-item @click.native="onChangePassword"
              >Cambiar mi contraseña</el-dropdown-item
            >
            <el-dropdown-item @click.native="onLogout"
              >Cerrar sesión</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>

      <el-main>
        <router-view />
        <ModalChangeMyPassword :show.sync="showModalChangePassword" />
        <ModalUpdateProfile :show.sync="showModalProfile" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalChangeMyPassword from '@/components/ModalChangePassword.vue';
import ModalUpdateProfile from '@/components/ModalProfile.vue';

export default {
  name: 'DashboardLayout',
  components: {
    ModalChangeMyPassword,
    ModalUpdateProfile,
  },
  data: () => ({
    showModalChangePassword: false,
    showModalProfile: false,
  }),
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    onUpdateProfile() {
      this.showModalProfile = true;
    },
    onChangePassword() {
      this.showModalChangePassword = true;
    },
    onLogout() {
      this.$confirm('¿deseas salir del sistema?', 'Atención', {
        confirmButtonText: 'Cerrar sesión',
        cancelButtonText: 'Cancelar',
        type: 'warning',
      })
        .then(() => {
          this.$axios.post('logout').then(() => {
            this.$store.dispatch('onLogout');
            this.$router.push({ name: 'login' });
          });
        })
        .catch(() => {});
    },
  },
};
</script>
