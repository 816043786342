const baseUrl = process.env.VUE_APP_BASE_URL;
const _token = localStorage.getItem('token');

const onImageupload = (blobInfo, success, failure, progress) => {
  var xhr, formData;
  const path = 'api/images/upload';

  xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  xhr.open('POST', `${baseUrl}${path}`);
  xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
  xhr.setRequestHeader('Authorization', _token);

  xhr.upload.onprogress = function (e) {
    progress((e.loaded / e.total) * 100);
  };

  xhr.onload = function () {
    var json;

    if (xhr.status === 403) {
      failure('HTTP Error: ' + xhr.status, { remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      failure('HTTP Error: ' + xhr.status);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.location != 'string') {
      failure('Invalid JSON: ' + xhr.responseText);
      return;
    }

    success(json.location);
  };

  xhr.onerror = function () {
    failure(
      'Image upload failed due to a XHR Transport error. Code: ' + xhr.status
    );
  };

  formData = new FormData();
  formData.append('image', blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
};

export { onImageupload };
