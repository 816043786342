<template>
  <div class="content">
    <div class="content__title">
      <strong>
        <slot name="title"></slot>
      </strong>
      <div class="content__title-btns">
        <slot name="btns"></slot>
      </div>
    </div>
    <div class="content__main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WrapperContentComponent',
};
</script>
