import Vue from 'vue';
import vueDebounce from 'vue-debounce';
import VueMce from 'vue-mce';
import router from './router';
import store from './store';
import '@/plugins/validator';
import '@/plugins/axios';
import App from './App.vue';
import WrapperContent from '@/components/WrapperContent.vue';

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/es';
import '@/styles/element.scss';
Vue.use(ElementUI, {
  locale,
});
Vue.use(VueMce);
Vue.use(vueDebounce);

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    onAxiosErrors422(error, formName) {
      const status = error.response.status;
      if (status === 422) {
        const data = error.response.data;
        this.onSetErrors(data.errors, formName);
      }
    },
    onSetErrors(errors, formName) {
      this.$refs[formName].setErrors(errors);
    },
  },
});

Vue.component('wrapper-content', WrapperContent);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
